














import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import API from '../../components/org/API.vue';
@Component({
  components: {
    API,
  },
})
export default class APIClass extends BaseVue {
  public selectedTab = 0;
}
